import React, { useEffect } from 'react'
import "../Styles/chatGpt.css"
import { getChatResponse } from '../Api/api'

/*
Here's how this will work:
Any time a play makes a guess (and get's it wrong)
OR, they go to the next clue, chatGPT will make a guess (if it's enabled)
The Guesses remaining and "Next" "Previous" and "Submit" buttons will disappear
It will be replaced with a text both where the user can see chatGPT getting prompted with a clue 
And returning a response
If chatGPT gets the wrong answer, it will show up the Crossword letter boxes and do the incorrect animation
If it get's the correct answer, it will tell the user that chatGPT got the correct answer and 
But not show the user what was returned by chatGpt. 
The user will be able to review everything said by chatGPT at the end of the game 
*/

const ChatGpt = () => {

    const fetchChatGptResponse = async () => {
        const chatRequestBody = {
            "model": "gpt-4o-mini",
            "messages": [
                {
                    "role": "system",
                    "content": "You are a helpful assistant"
                },
                {
                    "role": "user",
                    "content": "What will be the next trillion dollar business"
                },
            ]
        }
        const response = await getChatResponse(chatRequestBody)
        console.log("!!!!!!!!!!!!!!!!!!")
        console.log(response?.choices?.[0]?.message?.content)
        console.log("!!!!!!!!!!!!!!!!!!")
    }

    useEffect(() => {
        fetchChatGptResponse()
    }, [])
    return <div className='chatgpt-container'>

    </div>
}

export default ChatGpt