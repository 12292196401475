
/**
 * CONTROLLER - chatGPT
 * FUNCTION - getChatResponse
 * VERIFY - any user 
 * @param {*} chatRequestBody The body containing the chat request we send to chatGPT API
 * @returns The chat response from the chatGPT API
 */

export const getChatResponse = async (chatRequestBody) => {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_CHATGPT__API_KEY}`
        },
        body: JSON.stringify({
            "model": chatRequestBody.model,
            "messages": chatRequestBody.messages
        })
    })
    const result = await response.json();
    return result;
}