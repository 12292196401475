const controllers = {
    puzzle: require("./controllers/puzzle"),
    chatGPT: require("./controllers/chatGPT")
};

export const getPuzzleByNumber = async (puzzleNumber) => {
    return controllers.puzzle.getPuzzleByNumber(puzzleNumber)
}

export const submitPuzzle = async (puzzleNumber, submissionData) => {
    return controllers.puzzle.submitPuzzle(puzzleNumber, submissionData)
}

export const getStats = async (puzzleNumber) => {
    return controllers.puzzle.getStats(puzzleNumber)
}

export const getChatResponse = async (chatRequestBody) => {
    return controllers.chatGPT.getChatResponse(chatRequestBody)
}